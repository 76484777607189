<template>
    <header class="main-header module-header">




            <div class="card">
                <b-navbar>
                    <template #brand>
                        <b-navbar-item tag="router-link" :to="{ path: '/' }">
                            <img
                                src="https://capiassur.fr/wp-content/uploads/2021/12/capiasseur.png.webp"
                                alt="Lightweight UI components for Vue.js based on Bulma"
                            >
                        </b-navbar-item>
                    </template>
                    <template #start>
                        <b-navbar-item href="/">
                            Acceuil
                        </b-navbar-item>
                        <b-navbar-item href="/job-list">
                            Liste des Offres
                        </b-navbar-item>

                        <b-navbar-item href="https://capiassur.fr" target="_blank">
                            Qui sommes nous
                        </b-navbar-item>

                    </template>


                </b-navbar>
            </div>



       <!-- <MenuElement/> -->
    </header>

</template>
<script>

// import MenuElement from '@/components/MenuElement.vue';
export default {
  name: 'HeaderElement',
  components: {
    // MenuElement
  }
}
</script>


<style>
</style>
