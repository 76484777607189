<template>
    <div>
        <LoaderSpinner :isLoading="isLoading" />
        <div class="box">
            <div id="message" v-if="isDone">
                <div class="notification is-success">
                    Merci ! Votre condidature a ete envoyé </div>
            </div>
            <div id="formulaire" v-if="!isDone">
                <form @submit.prevent="Submit">
                    <div class="container">
                        <div class="columns">
                            <div class="column">
                                <b-field label="Nom">
                                    <b-input placeholder="Nom" v-model="FormularData.first_name" expanded
                                        required></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Prénom">
                                    <b-input placeholder="Prénom" v-model="FormularData.last_name" expanded
                                        required></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-field label="Genre">
                                    <b-select v-model="FormularData.genre" placeholder="Genre">
                                        <option value="Homme">Homme</option>
                                        <option value="Femme">Femme</option>
                                    </b-select>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Date de naissance">
                                    <b-datepicker :max-date="maxDate" v-model="FormularData.naissance_date" required
                                        :show-week-number="Config.showWeekNumber"
                                        placeholder="Selectionner votre date de naissance." id="naissanceDate"
                                        ref="datepicker">
                                    </b-datepicker>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-field label="Télephone">
                                    <b-input placeholder="Numéro de téléphone" v-model="FormularData.phone" type="text"
                                        required icon="fa fa-phone-alt fa" icon-right-clickable>
                                    </b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Email">
                                    <b-input placeholder="Adresse e-mail" v-model="FormularData.email" type="email"
                                        icon="fa fa-envelope fa" required>
                                    </b-input>
                                </b-field>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <b-field class="file is-primary">
                                    <label class="label"> Importer votre CV (Word/PDF) </label>
                                    <b-upload v-model="FormularData.cv_file" class="file-label"
                                        v-on:change.native="uploadFile">
                                        <span class="file-cta">
                                            <b-icon class="file-icon" icon="upload"></b-icon>
                                            <span class="file-label">Choisir un fichier</span>
                                        </span>
                                        <div class="file-name" v-if="FormularData.cv_file">
                                            {{ FormularData.cv_file.name }}
                                        </div>
                                    </b-upload>
                                </b-field>
                            </div>
                            <!-- <div class="column">
                                    <b-field label="Skills">
                                        <b-input placeholder="Skills" v-model="FormularData.willing_relecate"></b-input>
                                    </b-field>
                                </div> -->
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-field label="Pays">
                                    <b-input placeholder="Pays" v-model="FormularData.city" type="text"
                                        icon="fa fa-phone-alt fa" icon-right-clickable>
                                    </b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Adresse">
                                    <b-input placeholder="Adresse" v-model="FormularData.address" type="Adresse"
                                        icon="fa fa-envelope fa">
                                    </b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-field label="Diplome">
                                    <b-select placeholder="Diplome">
                                        <option v-for="option in Qualification" :key="option.id">
                                            {{ option.id }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Spécialité">
                                    <b-input placeholder="Specialite" v-model="FormularData.speciality" type="text"
                                        icon="fa fa-envelope fa">
                                    </b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-field label="Salaire Actuel">
                                    <b-input placeholder="Salaire Actuel" v-model="FormularData.current_salary"
                                        type="number" required icon="fa fa-envelope fa">
                                    </b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Attente salariale">
                                    <b-input placeholder="Attentes salariales" v-model="FormularData.salary_expectation"
                                        required type="number" icon="fa fa-envelope fa">
                                    </b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-field label="Organisation Actuelle">
                                    <b-input placeholder="Organisation Actuelle" v-model="FormularData.organisation"
                                        expanded></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Statut d'emploi">
                                    <b-input placeholder="Statut d'emploi" v-model="FormularData.employement_status"
                                        expanded></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-field label="Titre">
                                    <b-input placeholder="Titre" v-model="FormularData.title" expanded></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Expérience (Ans)">
                                    <b-input placeholder="Expérience" v-model="FormularData.total_experience"
                                        expanded type="number" required></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <b-field label="Période de passation (Mois)">
                                    <b-input placeholder="Période de passation" v-model="FormularData.notice_periode"
                                        expanded type="number"></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Disponibilité">
                                    <b-datepicker :max-date="maxDate" v-model="FormularData.available_from"
                                        :show-week-number="Config.showWeekNumber"
                                        placeholder="Selectionner votre Disponible depuis." id="naissanceDate"
                                        ref="datepicker">
                                    </b-datepicker>
                                </b-field>
                            </div>
                        </div>

                        <div class="columns">
                            <div class="column">
                                <b-field label="LinkedIn">
                                    <b-input placeholder="LinkedIn" v-model="FormularData.linkedin"></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Skype ID">
                                    <b-input placeholder="Skype ID" v-model="FormularData.skype"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                            <label>Parrainé Par : </label>
                            </div>
                        </div>
                        <div class="columns">

                            
                            <div class="column">
                                <b-field label="Nom">
                                    <b-input placeholder="Nom" v-model="FormularData.parinnageNom"></b-input>
                                </b-field>
                            </div>
                            <div class="column">
                                <b-field label="Prénom">
                                    <b-input placeholder="Prénom" v-model="FormularData.parinnagePrenom"></b-input>
                                </b-field>
                            </div>
                        </div>
                        <div id="submitform">
                            <button type="submit" class="button"> Envoyer ma condidature </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

    </div>
</template>

<script>
import Buefy from "buefy";
import Vue from "vue";
import { submitcondidaturecv } from "../_services/api";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
Vue.use(Buefy, {
    defaultIconPack: "fa",
    defaultContainerElement: "#content",
});
export default {
    name: "ApplicationView",
    components: {
        LoaderSpinner
    },
    data() {
        return {
            isDone: false,
            id: null,
            FormularData: {
                cv_file: null,
                linkedin: null,
                skype: null,
                notice_periode: 0,
                relevant_experience: 0,
                total_experience : 0,
                title: null,
                parinnageNom: null,
                parinnagePrenom: null,
                employement_status: null,
                organisation: null,
                genre: "Homme",
                naissance_date: new Date(),
                available_from: new Date(),
                current_salary: 0,
                salary_expectation: 0,
                first_name: null,
                last_name: null,
                email: null,
                phone: null,
                city: null,
                address: null,
                qualification: null,
                speciality: null,
                willing_relecate: null,

            },
            maxDate: new Date(),
            data: [],
            isFullPage: true,
            isLoading: false,
            Qualification: [
                { id: "Pas disponible" },
                { id: "Baccalauréat" },
                { id: "Licence" },
                { id: "Master" },
                { id: "Ingénierie" },
                { id: "Doctorat" }
            ],
            Config: {
                showWeekNumber: false,
            },
        };
    },
    mounted() {
        this.id = this.$route.params.id;
    },
    methods: {
        uploadFile(e) {
            const files = e.target.files;
            this.FormularData.cv_file = files[0];
        },
        alertCustomError(message) {
            this.$buefy.dialog.alert({
                title: 'Error',
                message: message,
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true
            })
        },
        Submit() {
            let there = this;
            const formData = new FormData();
            Object.entries(this.FormularData).forEach(entry => {
                const [key, value] = entry;
                console.log(key, value);
                formData.append(key, value);
            });

            this.isLoading = true;
            submitcondidaturecv(formData, this.id).then(function (res) {
                if (res.data.success) {
                    there.isLoading = false;
                    there.isDone = true;
                }
            })
                .catch(function (e) {
                    there.isLoading = false;
                    there.alertCustomError(e.response.data.message);
                });
        },
    }
};

</script>
<style>
.select {
    width: 100%;
}

select {
    width: 100%;
}

.label {
    margin-right: 5px;
    padding-top: 6px;
}

.button {
    background-color: rgb(38, 105, 146) !important;
    border: medium none !important;
    color: rgb(255, 255, 255) !important;
    margin: auto;
}

.button:hover {
    background-color: rgb(64, 128, 169) !important;
}

#submitform {
    text-align: center;
}

.hideclass {
    display: none;
}

.dialog.has-custom-container {
    position: fixed !important;
}
</style>
