<template>
    <section>
        <LoaderSpinner :isLoading="isLoading" />
        <div class="card" v-if="!isLoading">
            <div class="card-content">
                <div class="media row">
                    <div class="media-content">
                        <p class="title is-4">{{ items.title }}</p>
                        <p class="subtitle is-6">{{ items.position }}</p>
                        <p class="subtitle is-6">{{ items.departement }}</p>
                        <div id="job-description" v-html="items.description"></div>
                    </div>
                </div>
            </div>
            <footer class="card-footer">
                <div class="media-content col-md-6 btn">
                    <router-link :to="'/postuler/' + id" class="button">Postuler</router-link>
                </div>
            </footer>
        </div>
    </section>
</template>

<script>
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import { getJobsdetails } from '../_services/api';
export default {
    name: "DetailsView",
    components: {
        LoaderSpinner
    },
    data() {
        return {
            isLoading: true,
            id: null,
            items: [],
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.getSingleJobdetails();
    },

    methods: {
        getSingleJobdetails() {
            getJobsdetails(this.id)
                .then((response) => (this.process(response.data)))
                .catch(function () {
                })
        },
        process(items) {
            this.items = items;
            this.isLoading = false;
        }
    },
};
</script>
<style>
.card {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    margin: 50px;
}

.button {
    background-color: rgb(38, 105, 146) !important;
    border: medium none !important;
    color: rgb(255, 255, 255) !important;
    margin: auto;
}

.button:hover {
    background-color: rgb(64, 128, 169) !important;
}
</style>
