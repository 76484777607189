import Vue from "vue";
import Router from "vue-router";
import Home from './views/Home.vue'
import Details from './views/Details.vue'
import Application from './views/Application.vue'
import List from './views/List.vue'
Vue.use(Router);

const scrollBehavior = () => {
    return {
            top: 0,
            left: 0
            }
};
const router =  new Router({
  linkExactActiveClass: "active",
  mode: 'history',
  routes: [
    {
        path: '/postuler/:id',
        name: 'application-form',
        component: Application,
        meta: {title: 'Postuler - Capiassur Hiring', scrollTop: 0}
      },
    {
        path: '/job-details/:id',
        name: 'job-details',
        component: Details,
        meta: {title: 'Details - Capiassur Hiring', scrollTop: 0}
      },
    {
        path: '/job-list',
        name: 'job-list',
        component: List,
        meta: {title: 'List des offres - Capiassur Hiring', scrollTop: 0}
      },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {title: 'Acceuil - Capiassur Hiring', scrollTop: 0}
      },
  ],
  scrollBehavior
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.scrollTo(0,0);
  window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Softisme Coding and Consulting | Web Design | Web Development | Mobile Development | UI/UX Design | Outsourcing | Dedicated Team | Project Based Team';
  next();
});
export default router;
