<template>
    <div class="home">
        <List />
    </div>
</template>

<script>
import List from "./List.vue";
export default {
    name: "HomeView",
    components: {
        List,

    },
};
</script>
