<template>
    <section>
        <LoaderSpinner :isLoading="isLoading" />
        <div class="box" v-if="!isLoading">
            <div class="card" v-for="item in items" :key="item.id">
                <div class="card-content">
                    <div class="media row">
                        <div class="media-content col-md-6">
                            <p class="title is-4">{{ item.title }}</p>
                            <p class="subtitle is-6">{{ item.position }}</p>
                        </div>
                        <div class="media-content col-md-6 btn">
                            <router-link :to="'/job-details/' + item.id" class="button">Voir l'offre</router-link>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import { getJobs } from '../_services/api';
export default {
    name: "ListView",
    components: {
        LoaderSpinner
    },
    data() {
        return {
            isLoading: true,
            items: [],
        };
    },
    mounted() {
        this.getAllJobs();
    },

    methods: {
        getAllJobs() {
            getJobs()
                .then((response) => (this.process(response.data)))
                .catch(function () {
                });
        },
        process(items) {
            this.items = items;
            this.isLoading = false;
        }
    },
};
</script>
<style>
.card {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    margin: 50px;
}

.button {
    background-color: rgb(38, 105, 146) !important;
    border: medium none !important;
    color: rgb(255, 255, 255) !important;
    text-align: end;
}

.button:hover {
    background-color: rgb(64, 128, 169) !important;
}

.btn {
    text-align: end !important;
}
</style>
