import axios from "axios";
import { config } from '../config.js'
import { header, headerToken, headerMultipart } from './headers'


export const requestQuote = (data) => {
    return axios.post(config.hiringUrl + 'contactform/requestquote', data, header)
}

export const getJobs = () => {
    return axios.get(config.hiringUrl + '/jobs', headerToken)
}
export const getJobsdetails = (id) => {
    return axios.get(config.hiringUrl + '/job/'+id, headerToken)
}
export const submitcondidaturecv = (data , id) => {
    return axios.post(config.hiringUrl + '/job/'+id+'/submit', data, headerMultipart)
}
