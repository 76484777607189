export const header =
{
    headers: {
        "content-type": "application/json",
    },
}

export const headerToken = {
    headers: {
        Authorization: "Bearer " + localStorage.token,
    },
}

export const headerMultipart =
{
    headers: {
        "content-type": "multipart/form-data",
    },
}