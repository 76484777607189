<template>
    <footer class="main-footer bgc-dark-blue text-white rel z-1 module-footer">
       Footer
    </footer>
</template>
<script>
export default {
    name: "FooterElement"
};
</script>

<style></style>
